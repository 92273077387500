/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .main_container {
    /* margin: 0 360px 0 360px; */
    margin: 0 auto;
    max-width: 680px;
  }

  .navbar {
    padding: 0 !important;
  }

  .navbar_container {
    padding-top: 30px;
    background-color: white;
    margin-bottom: 20px;
  }

  .home_grid_col_img {
    display: none;
  }

  .home_title {
    font-size: 46px;
    line-height: 58px;
    color: #333333;
  }

  .home_text {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #0D2781;
    margin-top: 24px;
  }

  .home_paragraph {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;

    color: #636363;
    margin: 40px 0 40px 0;
  }

  .home_social {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .home_social a {
    text-decoration: none !important;
    color: #636363;
  }

  .about_container {
    margin-top: 60px;
  }

  .about_grid_row {

    display: grid !important;
  }

  .about_grid1 {
    background-image: url('../public/rec.png');
    color: white;
    background-size: 100% 100%;
    padding: 56px;
    margin-bottom: 60px;
  }

  .group_name {
    width: 136px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;

    color: #636363;
  }

  .job_group {
    margin: 16px 0;
  }

  .job_title {
    width: 381px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    color: #636363;

  }

  .duration {
    width: 250px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    display: block;
    color: #0D2781;
  }

  .footer_container {
    margin: 30px 120px;
    text-align: center;
    background-image: url('../public/footer.svg');
    background-size: 100% 100%;
    padding: 90px 444px;
  }

  .footer_title {


    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 49px;
    /* identical to box height */

    text-align: center;

    color: #333333;

  }

  .footer_mail {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 5px;
    color: #0D2781;
    text-decoration: none;
  }
}