@media only screen and (max-width: 768px) {
    .main_container {
        padding-top: 80px;
    }
    .company_logo {
        width: 44px;
        height: 44px;
    }

    .bio_container {
        padding: 0px 26px !important;
    }

    .bio_container,
    .bc_2_2 {
        flex-direction: column;
    }

    .d_button {
        text-align: center;
    }

    .experience_container {
        padding: 26px !important;
    }

    .ec_1_2_desc_container{
        flex-direction: column;
        align-items: baseline;
        gap: 8px;
        margin-top: 4px
    }
    .ec_1_2_desc_dot{
        display: none;
    }

    .ec_1 {
        padding: 20px
    }

    .mc_1 {
        padding: 0px 26px !important;
    }

    .mc_2 {
        flex-direction: column;
        align-items: center;
    }
    .footer_container{
        padding: 0px 26px !important;
    }
    .fc_1_2{
        flex-direction: column;
        gap: 16px
    }
    .fc_1_2_2{
        flex-direction: row-reverse;
        justify-content: left;
    }
    .ec_1_2_title_p1, .ec_1_2_title_p2{
        font-size: 18px;
        line-height: 20px;
    }
}