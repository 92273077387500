body {
    font-family: "Inter", sans-serif;
}

/* div {
    border: 1px solid green
} */

.body_container {
    display: grid;
    padding: 0;
    gap: 116px;
    padding: 0px !important;
}

.bio_container {
    padding: 0px 56px !important;
    display: flex;
    gap: 38px;
    align-content: stretch;
}

.me_img {
    width: 104px;
    border-radius: 50%;
}

.bc_2 {
    flex: 1;
    display: grid;
    gap: 40px
}

.bc_2_1 {
    display: grid;
    gap: 16px
}

.customH1 {
    color: rgba(32, 32, 32, 1);
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
}

.description {
    color: rgba(119, 119, 119, 1);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.d_color {
    color: rgba(41, 109, 241, 1);
}

.bc_2_2 {
    display: flex;
    gap: 16px;
}

.d_button {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(119, 119, 119, 1);
    background: rgba(247, 247, 247, 1);
    border: 1px solid rgba(247, 247, 247, 1);
    padding: 8px 24px;
    border-radius: 16px;
    transition: color 0.3s ease-in-out;
    transition: border 0.3s ease-in-out;
}
.d_button:hover{
    color: rgba(41, 109, 241, 1);
    border: 1px solid rgba(41, 109, 241, 1) ;
}

.experience_container {
    padding: 56px !important;
    background: #F7F7F7;
    border-radius: 40px;
    display: grid;
    gap: 32px;
}

.customH2 {
    color: #202020;
    font-size: 32px;
}
.ec_link{
    text-decoration: none;
}
.ec_1 {
    position: relative;
    display: flex;
    gap: 24px;
    padding: 24px;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 1);

}
/* .ec_1:hover .d_button_arrow{
    bottom: 30px; 
    opacity: 1; 
} */

.ec_1_2 {
    flex: 1;
    gap: 4px
}

.ec_1_2_title_p1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(32, 32, 32, 1);
}

.ec_1_2_title_p2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(119, 119, 119, 1);
}
.ec_1_2_desc_container {
    display: flex;
    align-items: center;
    gap: 4px;
}
.ec_1_2_desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #777777
}
.ec_1_2_desc_blue {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #296DF1
}
.ec_1_2_desc_dot{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #777777
}
.me_container{
    display: grid;
    gap: 56px
}
.mc_1{
    padding: 0px 56px !important;
    display: grid;
    gap: 16px
}
.mc_1_custom{
    display: grid;
    gap: 16px
}
.mc_2{
    display: flex;
    gap: 25px;
    justify-content: space-between;
}
.mc_2_inner{
    display: grid;
    gap: 25px;
}
.mc_2_inner_div{
    max-width: 260px;
}
.me_description{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(119, 119, 119, 1)

}
.tea-img{
    width: 100%;
    height: 100%;
    border-radius: 24px;
    object-fit: cover;
}
.tea-img-custom{
    width: 260px;
    border-radius: 24px;
    object-fit: cover;
    height: 100%;
}
.tea-img-custom-1{
    width: 100%;
    height: 80%;
    border-radius: 24px;
    object-fit: cover;
}
.tennis-text{
    max-width: 260px;
    display: grid;
    gap: 16px;
    margin-top: 9px;
    padding: 8px;
}
.tennis-quote{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #777777;
    font-style: italic;
}
.tennis-name{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: right;
    color: #777777
}
.footer_container{
    margin-top: 116px;
    padding: 0px 56px !important;
    display: grid;
    gap: 56px
}
.fc_1_1{
    display: grid;
    gap:16px
}
.fc_1_2{
    display: flex;
    justify-content: space-between;
}
.fc_1_2_2{
    display: flex;
    gap: 16px;
}
.git_btn{
    width: 164px;
    height: 44px;
    padding: 8px, 24px, 8px, 24px;
    border-radius: 16px;
    background-color: rgba(41, 109, 241, 1);
    color: white
}
.d_button_w_logo {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(119, 119, 119, 1);
    background: rgba(247, 247, 247, 1);
    padding: 13px;
    border-radius: 16px;
}
.social_logo{
    width: 18px;
    height: 18px;
}
.d_button_arrow {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    padding: 13px;
    border-radius: 16px;
    position: absolute;
    bottom: -10px; /*  Position the button below the container initially */
    left: 90%;
    transform: translateX(-50%);  /* Center the button horizontally */
    opacity: 0; /* Hide the button initially */
    transition: all 0.4s ease-in-out; 
}

.company_logo{
    width: 55px;
    height: 55px;
}
.no-deco-link{
    text-decoration: none;
    color: rgba(119, 119, 119, 1);
}